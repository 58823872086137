@import ../../styles/helpers


.form
    position: relative


.inputCheck
    padding: 0 48px 0 14px
    margin-bottom: 8px
    border-radius: 24px
    background: none
    border: 2px solid #E6E8EC
    margin-right: 6px
    


.input
    border: orange solid 1px
    width: 100%
    height: 48px
    padding: 0 48px 0 14px
    margin-bottom: 8px
    border-radius: 24px
    background: none
    border: 2px solid $neutrals6
    +poppins
    +caption-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    &:focus
        border-color: $neutrals4
    &:error
        border: red solid 4px
    +placeholder
        //color: $neutrals4

input.inputError,
select.inputError 
    
    width: 100%
    height: 48px
    padding: 0 48px 0 14px
    margin-bottom: 8px
    border-radius: 24px
    background: none
    border: 2px solid red
    +poppins
    +caption-1
    color: $neutrals2
    transition: border-color .2s
    +dark
        border-color: $neutrals3
        color: $neutrals8
    &:focus
        border-color: $neutrals4
    &:error
        border: red solid 4px
    +placeholder
        //color: $neutrals4

small
    color: red
    margin: 0 0 0 0px

.btn
    position: absolute
    top: 8px
    right: 8px
    bottom: 8px
    width: 32px
    height: 32px
    border-radius: 50%
    background: $red
    transition: background .2s
    svg
        fill: $neutrals8
    &:hover
        background: darken($red, 10)

button
    margin-bottom: 20px
    &:disabled
        opacity: .35


@media screen and (min-width: 1160px)
    .stackForm
        width: 32% 
        display: inline-block
        span
            display: flex
            flex-direction: column
    .stackForm ~ .stackForm
        margin-left: 2%
    .stackForm span
        width: 100%


.agreement
    font-size: 0.8rem

.btns
    margin-top: 1rem