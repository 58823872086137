[class^="stage"]
    margin-bottom: 12px
    +hairline-1
  
    .dark-mode &
        color: $neutrals10
    +m
        font-size: 12px


.stage-small
    font-size: 14px