@import ../../styles/helpers

.container
    max-width: 896px

.wrap
    margin: 0 -12px
    text-align: center

.logo
    display: inline-block

.title
    margin-bottom: 40px
    padding-bottom: 40px
    border-bottom: 1px solid $neutrals6
    +sf-pro-display
    font-size: 40px
    line-height: 1.2
    +m
        font-size: 32px
    +dark
        border-color: $neutrals3

.author
    margin-bottom: 4px
    font-weight: 600

.position
    +caption-1
    //color: $neutrals4