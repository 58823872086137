@import ../../styles/helpers

.head
    max-width: 580px
    margin: 0 auto 80px
    text-align: center
    +d
        margin-bottom: 64px
    +m
        text-align: left
        max-width: 100%

.stage
    margin-bottom: 8px
    //color: $neutrals4

.list
    display: flex
    justify-content: space-between
    max-width: 600px
    margin: 0px auto

.listCosts
    display: flex
    max-width: 800px
    margin: 0px auto
    gap: 24px
    +s
        display: grid
        grid-template-columns: 45%

.holder
    height: 100%
    padding: 24px 32px
    background: $neutrals6
    border-radius: 20px
    transition: all .2s
    flex: 1
    +d
        padding-left: 24px
        padding-right: 24px

    +dark
        background: $neutrals2
        &:hover
            background: transparent
            box-shadow: inset 0 0 0 1px $neutrals2
    
.item_one
    +s
        grid-column: 1 / span 1

.item_two
    +s
        grid-column: 2 / span 1

.item_three
    +s
        grid-column: 1 / span 2

.holderText
    text-align: center
    font-weight: 300
    margin: 8px auto 0
    font-size: 3.2rem
    +m
        font-size: 2rem

.holderLabel
    margin: 0 auto
    text-align: center
    min-height: 50px
    line-height: 1.2
    font-size: 1.1rem
    +m
        font-size: 0.9rem 
    +s
        font-size: 0.8rem
        min-height: 30px

.holderIcon
    heigh: 32px
    width: 44px
    position
    background-color: $neutrals8
    border-radius: 50px
    padding: 10px
    margin: 0 auto 0px
    text-align: center
    


.item
    flex: 0 0 31%
    padding: 34px 83px
    +x
        padding: 34px 64px
    +d
        padding: 32px
    +m
        padding: 0 
    &:first-child
        padding-left: 0
    &:nth-child(2)
        flex: 0 0 38%
        border-width: 0 1px
        border-style: solid
        border-color: $neutrals6 
        +m
            border: none
        +dark
            border-color: $neutrals3
    &:last-child
        padding-right: 0
    &:not(:last-child)
        +m
            margin-bottom: 24px
            padding-bottom: 24px
            border-bottom: 1px solid $neutrals6
            +dark
                border-color: $neutrals3


.icon
    margin-bottom: 32px
    height: 24px
    margin: auto
    svg
        +dark
            fill: $neutrals8

.category
    margin-bottom: 32px
    font-weight: 600
    
.content
    +caption-1
    //color: $neutrals4



.selected,
.unselected
    position: relative
    display: flex
    justify-content: center
    align-items: center
    min-width: 100px
    min-height: 100px
    margin-bottom: 60px
    border-radius: 24px
    border: solid 1px $neutrals6
    font-size: 1.4rem
    box-shadow: 1px 2px 3px #bbb
    +m
        margin-bottom: 32px
    +s
        min-width: 70px
    &:before
        left: -36px
    &:after
        right: -36px    
    +dark
        border: solid 1px #555
        box-shadow: 0px 0px 0px #ccc

.selected
    color: white
    background-color: $bisque
    +dark
        border: solid 1px #DD436C
        box-shadow: 1px 2px 3px #DD436C